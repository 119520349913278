<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>Staff Affairs</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }">Dashboard</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Staff-Affairs' }">Staff Affairs</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Staff-Affairs-Staff' }">Staff</router-link>
          </li>
          <li class="breadcrumb-item active">Edit</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-md-9">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Edit Staff Info</h4>
          </div>
          <div class="card-body">
            <!-- FeedBack -->
            <feedback-messages :msgs="msgs" :errors="errors" />
            <!-- /FeedBack -->
            <div class="basic-form">
              <form id="staff_edit" @submit.prevent="submitFormData()">
                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Name <span style="color: red">*</span></label>
                  <div class="col-md-3">
                    <input type="text" name="firstname" required class="form-control" v-model="staff.firstname"
                      placeholder="First" />
                  </div>
                  <div class="col-md-3">
                    <input type="text" name="middlename" required class="form-control" v-model="staff.middlename"
                      placeholder="Middle" />
                  </div>
                  <div class="col-md-3">
                    <input type="text" name="lastname" required class="form-control" v-model="staff.lastname"
                      placeholder="Last" />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Email <span style="color: red">*</span></label>
                  <div class="col-md-9">
                    <input type="email" name="email" required class="form-control" v-model="staff.email"
                      placeholder="Email" />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Gender <span style="color: red">*</span></label>
                  <div class="col-md-9">
                    <select class="form-control" name="gender" required v-model="staff.gender">
                      <option value="" disabled selected>
                        Nothing Selected
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Phone <span style="color: red">*</span></label>
                  <div class="col-md-9">
                    <input type="text" name="phone" required class="form-control" v-model="staff.phone"
                      placeholder="Phone" />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Roles {{ staff.rolesIDs }}
                    <span style="color: red">*</span></label>
                  <div class="col-md-9">
                    <select class="form-control" name="roles[]" required v-model="staff.rolesIDs" multiple>
                      <option value="" disabled selected>
                        Nothing Selected
                      </option>
                      <option v-for="role in lists.roles" :key="role.id" :value="role.id">
                        {{ role.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row" style="padding-top: 2rem; margin-bottom: 0px">
                  <div class="col-md-12">
                    <button :disabled="disableBtn" name="submit" type="submit" class="btn btn-primary" value="Submit"
                      onclick="this.blur();">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-md-9">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Edit Staff Password</h4>
          </div>
          <div class="card-body">
            <div class="basic-form">
              <form id="" @submit.prevent="submitPasswordFormData()">
                <div class="form-group row">
                  <label class="col-md-3 col-form-label">Password <span style="color: red">*</span></label>
                  <div class="col-md-4">
                    <input type="password" name="password" required class="form-control" v-model="passwordData.password"
                      placeholder="Password" />
                  </div>
                  <div class="col-md-4">
                    <input type="password" name="password_confirmation" required class="form-control"
                      v-model="passwordData.password_confirmation" placeholder="Password Confirmation" />
                  </div>
                </div>
                <div class="form-group row" style="padding-top: 2rem; margin-bottom: 0px">
                  <div class="col-md-12">
                    <button :disabled="disableBtn" name="submit" type="submit" class="btn btn-primary" value="Submit"
                      onclick="this.blur();">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackMessages from "@/components/FeedbackMessages.vue";
import useStaff from "@/composables/staff";
import { ref, inject, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  components: {
    FeedbackMessages,
  },
  setup(props) {
    const passwordData = ref({});
    const disableBtn = ref(false);
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();

    const { getSingleStaff, updateStaff, updateStaffPassword, staff, lists, msgs, errors } =
      useStaff();

    onBeforeMount(async () => {
      //first: check the user Permission
      if (!hasPermission("edit_staff"))
        router.push({
          name: "401",
        });

      await getSingleStaff(props.id);
    });

    const submitFormData = async () => {
      swal.fire({
        title: "Please wait while submit your request",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      errors.value = [];
      disableBtn.value = true;

      await updateStaff(staff, props.id).then((resp) => {
        swal.close();
        if (resp != "fail") {
          router.push({
            name: "Staff-Affairs-Staff-Single",
            params: {
              id: resp,
            },
          });
        }
        window.scrollTo(0, 0);
        disableBtn.value = false;
      });
    };

    const submitPasswordFormData = async () => {
      swal.fire({
        title: "Please wait while submit your request",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      errors.value = [];
      disableBtn.value = true;

      await updateStaffPassword(passwordData, props.id).then((resp) => {
        swal.close();
        if (resp != "fail") {
          router.push({
            name: "Staff-Affairs-Staff-Single",
            params: {
              id: resp,
            },
          });
        }
        window.scrollTo(0, 0);
        disableBtn.value = false;
      });
    };

    return {
      submitFormData,
      submitPasswordFormData,
      staff,
      passwordData,
      disableBtn,
      lists,
      msgs,
      errors,
    };
  },
};
</script>
